export const lerp = (val1, val2, amount) => 
{
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return val1 + (val2 - val1) * amount;
}

var isScrolling = false;
export const smoothScrollTo = (destination) => 
{
    if(isScrolling) return;
    isScrolling = true;

    let destionationYPosition = destination.current.offsetTop;

    const timer = ms => new Promise(res => setTimeout(res, ms));

    const scrollTo = async () =>
    {
        
        // scroll down
        while(window.scrollY < destionationYPosition) 
        {

            window.scroll(0, 1 + lerp(window.scrollY, destionationYPosition, .03));


            await timer(4);

        }

        // scroll up
        while(window.scrollY > destionationYPosition) 
        {
            window.scroll(0, lerp(window.scrollY, destionationYPosition, .03));

 
            await timer(4);
        }

        isScrolling = false;
    }

    scrollTo();
}